@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Work Sans", sans-serif;
}
.box {
  background-image: url("https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/mjuim0jta4zcfsk5qv66");

  height: 240px; /* Example height */
  background-size: cover; /* Optional: to make the image cover the entire element */
}

.hero {
  background-size: 10px 10px;
  background-image: linear-gradient(
      to right,
      rgb(249, 245, 245) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgb(240, 240, 240) 1px, transparent 1px);
}

.card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.heading {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
